import ApiService from "@/common/api/api.service";

const GrupoEconomicoService = {
  listar : function(filtro, paginacao = 1, porPagina = 10){
    let link = `grupo-economico?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    link = link.concat(`${filtro ? `&TermoPesquisa=${filtro}` : ''}`);
    return ApiService.get(link);
  },
  salvar : function(form){
    if(form.id){
      return ApiService.put("grupo-economico", form);
    }
    return ApiService.post("grupo-economico", form);
  },
  ativarInativar : function(form, ativarInativar){
    if(ativarInativar){
      return ApiService.put(`grupo-economico/ativar`, form);
    }
    return ApiService.put(`grupo-economico/inativar`, form);
  },
  buscar : function(id){
    return ApiService.get(`grupo-economico/${id}`);
  },
  dropdown : function(){
    return ApiService.get(`grupo-economico/dropdown`);
  }
};

export default GrupoEconomicoService;
